import { RouteProps } from "react-router-dom";   
import { GroupList } from "./list/list.component"; 

export enum GroupRoute { 
  List = '/group/list'
}

export const GroupRouteMap = [
  {
    path: GroupRoute.List,
    component: GroupList,
  }, 
] as RouteProps[];
