import { RouteProps } from "react-router-dom";  
import { StudentList } from "./list/list.component";
import { StudentPost } from "./post/post.component";

export enum StudentRoute {
  Post = '/student/post',
  List = '/student/list'
}

export const StudentRouteMap = [
  {
    path: StudentRoute.List,
    component: StudentList,
  },
  {
    path: StudentRoute.Post,
    component: StudentPost,
  }
] as RouteProps[];
