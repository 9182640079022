import { RouteProps } from "react-router-dom"; 
import { RegionList } from "./list.component";
import { RegionPost } from "./post.component";

export enum RegionRoute {
  Post = '/region/post',
  List = '/region/list'
}

export const RegionRouteMap = [
  {
    path: RegionRoute.List,
    component: RegionList,
  },
  {
    path: RegionRoute.Post,
    component: RegionPost,
  }
] as RouteProps[];
