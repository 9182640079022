import { Acl } from "../../interfaces/core.interface";

export enum SpeakerAclCode { 
  ListAllSpeaker = 'list-all',
  ListOwnSpeaker = 'list-own', 
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const SpeakerAcl = [  
  {
    code: SpeakerAclCode.ListAllSpeaker,
    title: 'student.listAll.title',
    description: 'student.listAll.subTitle',
  },
  {
    code: SpeakerAclCode.ListOwnSpeaker,
    title: 'student.listOwn.title',
    description: 'student.listOwn.subTitle',
  },
  {
    code: SpeakerAclCode.Create,
    title: 'student.create.title',
    description: 'student.create.subTitle',
  },
  {
    code: SpeakerAclCode.Update,
    title: 'student.update.title',
    description: 'student.update.subTitle',
  },
  {
    code: SpeakerAclCode.Delete,
    title: 'student.delete.title',
    description: 'student.delete.subTitle',
  }, 
] as Acl<SpeakerAclCode>;
