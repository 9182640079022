import { RouteProps } from "react-router-dom";  
import { AnnouncementList } from "./list/list.component";
import { AnnouncementPost } from "./post/post.component";

export enum AnnouncementRoute {
  Post = '/announcement/post',
  List = '/announcement/list'
}

export const AnnouncementRouteMap = [
  {
    path: AnnouncementRoute.List,
    component: AnnouncementList,
  },
  {
    path: AnnouncementRoute.Post,
    component: AnnouncementPost,
  }
] as RouteProps[];
