import { RouteProps } from "react-router-dom";   
import { MentorList } from "./list/list.component";
import { MentorPost } from "./post/post.component";

export enum MentorRoute {
  Post = '/mentor/post',
  List = '/mentor/list'
}

export const StudentRouteMap = [
  {
    path: MentorRoute.List,
    component: MentorList,
  },
  {
    path: MentorRoute.Post,
    component: MentorPost,
  }
] as RouteProps[];
