import { Role } from "../entities/role.entity";
import { Interest } from "../entities/interest.entity"; 
import { DashboardRoute } from "../pages/dashboard/routes"; 
import { UserRoute } from "../pages/user/routes";     
import { User } from "../entities/user.entity";
import { InterestRoute } from "../pages/interest/routes";
import { RegionRoute } from "../pages/region/routes";
import { Region } from "../entities/region.entity";
import { MajorsRoute } from "../pages/majors/routes";
import { Majors } from "../entities/majors.entity";
import { Student } from "../entities/student.entity";
import { StudentRoute } from "../pages/student/routes";
import { Mentor } from "../entities/mentor.entity";
import { MentorRoute } from "../pages/mentors/routes";
import { Speaker } from "../entities/speaker.entity";
import { SpeakerRoute } from "../pages/speaker/routes";
import { AnnouncementRoute } from "../pages/announcement/routes";
import { Announcement } from "../entities/announcement";


export enum FormHeaderType {
  Title = "title",
  Controls = "controls",
}

export type FormCancelLink = 
    UserRoute |   
    DashboardRoute |
    InterestRoute |
    RegionRoute |
    MajorsRoute |
    StudentRoute |
    MentorRoute |
    SpeakerRoute |
    AnnouncementRoute

export type Entities = 
    Student |
    User | 
    Role |  
    Interest |
    Region |
    Majors |
    Mentor |
    Speaker |
    Announcement;

export enum FormStyle {
  Containerized = "Containerized",
}
