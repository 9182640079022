import { Acl } from "../../interfaces/core.interface";

export enum MentorAclCode { 
  ListAllMentor = 'list-all',
  ListOwnMentor = 'list-own', 
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const MentorAcl = [  
  {
    code: MentorAclCode.ListAllMentor,
    title: 'student.listAll.title',
    description: 'student.listAll.subTitle',
  },
  {
    code: MentorAclCode.ListOwnMentor,
    title: 'student.listOwn.title',
    description: 'student.listOwn.subTitle',
  },
  {
    code: MentorAclCode.Create,
    title: 'student.create.title',
    description: 'student.create.subTitle',
  },
  {
    code: MentorAclCode.Update,
    title: 'student.update.title',
    description: 'student.update.subTitle',
  },
  {
    code: MentorAclCode.Delete,
    title: 'student.delete.title',
    description: 'student.delete.subTitle',
  }, 
] as Acl<MentorAclCode>;
