import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component'; 
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';  
import { Group, Status } from '../../../entities/group.entity';
import { Api, Endpoint } from '../../../services/api.service';    

interface Props {
    selectedGroup: Group;
    getFullName: (group: Group) => string;  
}

export function SelectedItemContent({
    selectedGroup, getFullName
}: Props) {

    const [group, setGroup] = useState<Group>();
    // const [, setInspection] = useState<Inspection[]>();
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    // const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);

    useEffect(() => {
        init(); 
    }, [selectedGroup])

    const init = useCallback(async () => {
        setGroup(selectedGroup);
        const group = await Api.get<Group, { id: string }>(Endpoint.GROUP, { id: selectedGroup.id as string });
        console.log(group);
        setGroup(group);
    }, [selectedGroup]);

    const [confirmingApproval, setConfirmingApproval] = useState(false);
    const [approved, setApproved] = useState(false);

    useEffect(() => {
        setApproved(false);
        setConfirmingApproval(false);
    }, [selectedGroup]);  

    return (
        <ContentWrapper className="row">
            <ViewContent
                className="col-md-6"
                title={getFullName(selectedGroup)}
                // subtitle={selectedGroup.code} 
            >
                <ViewContentItems> 

                    {/* <ViewContentItem title="Email">
                        <a href={`mailto:${group?.email}`}>{group?.email}</a>
                    </ViewContentItem> */}

                    {/* <ViewContentItem title="Affiliation">
                        <a href={`${group?.affiliations}`}>{group?.affiliations}</a>
                    </ViewContentItem>   */}

                    <ViewContentItem title="Admin">
                        {group?.admin.firstName}
                    </ViewContentItem> 

                    <ViewContentItem title="Member">
                        {group?.members?.map(n => n.title).join(', ')}
                    </ViewContentItem>  
                    </ViewContentItems>
                    </ViewContent>
        </ContentWrapper>
    );
}