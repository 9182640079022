import { Acl } from "../../interfaces/core.interface";

export enum GroupAclCode { 
  ListAllGroup = 'list-all',
  ListOwnGroup = 'list-own',  
}

export const GroupAcl = [  
  {
    code: GroupAclCode.ListAllGroup,
    title: 'student.listAll.title',
    description: 'student.listAll.subTitle',
  },
  {
    code: GroupAclCode.ListOwnGroup,
    title: 'student.listOwn.title',
    description: 'student.listOwn.subTitle',
  },  
] as Acl<GroupAclCode>;
