import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use"; 
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter, StatusFilter } from "../../../components/list/filter.component"; 
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component"; 
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../services/api.service";
import { getStorageUrl } from "../../../util/file.util"; 
import MaleImg from '../../../img/male.png'; 
import { Speaker } from "../../../entities/speaker.entity";
import { SelectedItemContent } from "./content.component";
import { SpeakerPost } from "../post/post.component";

export enum ProfilePicSize {
    List = "admin_list",
    Single = "admin_single",
}

const groupFilters: GroupFilter[] = [
    { title: "all", icon: "phone-21" }, 
    { title: "active", icon: "user-check" }, 
    { title: "blocked", icon: "user-x" },
  ];
  
  const statusFilters: StatusFilter[] = [
    { title: "Online", status: ListItemStatus.Green },
    { title: "Metering", status: ListItemStatus.Blue },
    { title: "Offline", status: ListItemStatus.Red },
  ];

export function SpeakerList() {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [speaker, setSpeaker] = useState<Speaker[] | undefined>(undefined);
  const [selectedSpeaker, setSelectedSpeaker] = useState<Speaker>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [activeStatusFilter, setActiveStatusFilter] = useState<
    StatusFilter | undefined
  >(undefined);

  const { t } = useTranslation(['main', 'list']);

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const status = activeStatusFilter?.title;
    const { items } = await Api.get<IPaginationResponse<Speaker>, IPaginationOptions>(Endpoint.SPEAKER_LIST, { page: 0, limit, group, search });

    console.log('items:',items)
    if (items) {
      setSpeaker(items);
    }

    if (items.length > 0 && !selectedSpeaker) {
      setSelectedSpeaker(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedSpeaker]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll])

  const deleteService = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.SPEAKER, { id: selectedSpeaker?.id });
    setSelectedSpeaker(undefined);
    refresh();
  }

  const getProfilePic = (speaker: Speaker, type: ProfilePicSize): string => {
    if (speaker.picFile) {
      return `${getStorageUrl()}/speaker/${speaker.id}/pic/${
        speaker.picFile?.id
      }/${type}.${speaker.picFile?.extension}`;
    } else {
      return MaleImg;
    }
  };

  const getFullName = (speaker: Speaker): string => {
    return [speaker.firstName, speaker.lastName].join(" ");
  };

  const toListItems = (speaker?: Speaker): ListItem<Speaker> | undefined => {
    if (!speaker) {
      return;
    }
    return {
      id: speaker.id as string,
      title: getFullName(speaker),
      imgSrc: getProfilePic(speaker, ProfilePicSize.List),
      ref: speaker
    }
  }

  const addService = () => {
    setFormMode(FormMode.Adding);
  }

  const editService = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (speaker) {
      const currentServicesIndex = speaker.findIndex(speaker => !!selectedSpeaker && speaker.id === selectedSpeaker.id);
      if (currentServicesIndex + 1 < speaker.length && currentServicesIndex !== -1) {
        setSelectedSpeaker(speaker[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedSpeaker(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (speaker) {
      const currentServiceIndex = speaker.findIndex(speaker => !!selectedSpeaker && speaker.id === selectedSpeaker.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedSpeaker(speaker[currentServiceIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
      />

      <ListItems
        items={speaker?.map(toListItems) as ListItem<Speaker>[]}
        activeItem={toListItems(selectedSpeaker)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setSpeaker(undefined); refresh(); }}
        onClick={(item: ListItem<Speaker>) => { setSelectedSpeaker(item.ref); }}
      />

      { formMode === undefined ? (
        speaker !== undefined ? (
          selectedSpeaker ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true}
              onAdd={addService}
              onEdit={editService}
              onDelete={deleteService}
              onPrev={onPrev}
              onNext={onNext}
            >
              <SelectedItemContent
                selectedSpeaker={selectedSpeaker}
                getFullName={getFullName}
                getProfilePic={getProfilePic}
                onApprove={ refresh }
              />
            </View>
          ) : (
              <div className="mx-auto py-5 text-center">
                <button onClick={addService} className="btn btn-primary text-bold">{t("list:speaker.addSpeaker")}</button>
              </div>
            )
        ) : (
            <div className="mx-auto py-5 text-center">
              <p>{t("list:speaker.wait")}</p>
              <Loading loading={true} />
            </div>
          )
      ) : (
          selectedSpeaker ? (
            <SpeakerPost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedSpeaker.id}
              onAfterSave={() => refresh()}
            />
          ) : (
              <SpeakerPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
        )}
    </List>
  );

}