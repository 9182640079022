import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import { Loading } from "../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../components/list/filter.component";
import { ListItem, ListItems } from "../../components/list/items.component";
import { List } from "../../components/list/wrapper.component";
import { View } from "../../components/view/wrapper.component";  
import { Majors } from "../../entities/majors.entity";
import { FormMode } from "../../enums/core.enum";
import { FormStyle } from "../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../services/api.service';  
import { MajorsPost } from "./post.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function MajorsList() {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [majors, setMajors] = useState<Majors[] | undefined>(undefined);
  const [selectedRegion, setSelectedRegion] = useState<Majors>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);

  const { t } = useTranslation(['main', 'list']);

  const refresh = useCallback(async () => {
    const group = activeGroupFilter?.title
    const { items } = await Api.get<IPaginationResponse<Majors>, IPaginationOptions>(Endpoint.MAJORS_LIST, { page: 0, limit, group, search });

    if (items) {
      setMajors(items);
    }

    if (items.length > 0 && !selectedRegion) {
      setSelectedRegion(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedRegion]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll])

  const deleteService = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.MAJORS, { id: selectedRegion?.id });
    setSelectedRegion(undefined);
    refresh();
  }

  const toListItems = (service?: Majors): ListItem<Majors> | undefined => {
    if (!service) {
      return;
    }
    return {
      id: service.id as string,
      title: `${service.title}${service.isDefault ? ' (default)' : ''}`,
      ref: service
    }
  }

  const addService = () => {
    setFormMode(FormMode.Adding);
  }

  const editService = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (majors) {
      const currentServicesIndex = majors.findIndex(majors => !!selectedRegion && majors.id === selectedRegion.id);
      if (currentServicesIndex + 1 < majors.length && currentServicesIndex !== -1) {
        setSelectedRegion(majors[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedRegion(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (majors) {
      const currentServiceIndex = majors.findIndex(majors => !!selectedRegion && majors.id === selectedRegion.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedRegion(majors[currentServiceIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
      />

      <ListItems
        items={majors?.map(toListItems) as ListItem<Majors>[]}
        activeItem={toListItems(selectedRegion)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setMajors(undefined); refresh(); }}
        onClick={(item: ListItem<Majors>) => { setSelectedRegion(item.ref); }}
      />

      { formMode === undefined ? (
        majors !== undefined ? (
          selectedRegion ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true}
              onAdd={addService}
              onEdit={editService}
              onDelete={deleteService}
              onPrev={onPrev}
              onNext={onNext}
            >
              {/* <SelectedItemContent
                selectedService={ selectedService }
              /> */}
            </View>
          ) : (
              <div className="mx-auto py-5 text-center">
                <button onClick={addService} className="btn btn-primary text-bold">{t("list:major.addMajor")}</button>
              </div>
            )
        ) : (
            <div className="mx-auto py-5 text-center">
              <p>{t("list:major.wait")}</p>
              <Loading loading={true} />
            </div>
          )
      ) : (
          selectedRegion ? (
            <MajorsPost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedRegion.id}
              onAfterSave={() => refresh()}
            />
          ) : (
              <MajorsPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
        )}
    </List>
  );

}