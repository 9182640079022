import { RouteProps } from "react-router-dom";
import { InterestPost } from "./post.component";
import { InterestList } from "./list.component";

export enum InterestRoute {
  Post = '/interest/post',
  List = '/interest/list'
}

export const InterestRouteMap = [
  {
    path: InterestRoute.List,
    component: InterestList,
  },
  {
    path: InterestRoute.Post,
    component: InterestPost,
  }
] as RouteProps[];
