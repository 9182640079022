import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use"; 
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter, StatusFilter } from "../../../components/list/filter.component"; 
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component"; 
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../services/api.service";
import { getStorageUrl } from "../../../util/file.util"; 
import MaleImg from '../../../img/male.png'; 
import { Mentor } from "../../../entities/mentor.entity";
import { SelectedItemContent } from "./content.component";
import { MentorPost } from "../post/post.component";

export enum ProfilePicSize {
    List = "admin_list",
    Single = "admin_single",
}

const groupFilters: GroupFilter[] = [
    { title: "all", icon: "phone-21" }, 
    { title: "active", icon: "user-check" }, 
    { title: "blocked", icon: "user-x" },
  ];
  
  const statusFilters: StatusFilter[] = [
    { title: "Online", status: ListItemStatus.Green },
    { title: "Metering", status: ListItemStatus.Blue },
    { title: "Offline", status: ListItemStatus.Red },
  ];

export function MentorList() {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [mentor, setMentor] = useState<Mentor[] | undefined>(undefined);
  const [selectedMentor, setSelectedMentor] = useState<Mentor>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [activeStatusFilter, setActiveStatusFilter] = useState<
    StatusFilter | undefined
  >(undefined);

  const { t } = useTranslation(['main', 'list']);

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const status = activeStatusFilter?.title;
    const { items } = await Api.get<IPaginationResponse<Mentor>, IPaginationOptions>(Endpoint.MENTOR_LIST, { page: 0, limit, group, search });

    console.log('items:',items)
    if (items) {
      setMentor(items);
    }

    if (items.length > 0 && !selectedMentor) {
      setSelectedMentor(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedMentor]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll])

  const deleteService = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.MENTOR, { id: selectedMentor?.id });
    setSelectedMentor(undefined);
    refresh();
  }

  const getProfilePic = (mentor: Mentor, type: ProfilePicSize): string => {
    if (mentor.picFile) {
      return `${getStorageUrl()}/mentor/${mentor.id}/pic/${
        mentor.picFile?.id
      }/${type}.${mentor.picFile?.extension}`;
    } else {
      return MaleImg;
    }
  };

  const getFullName = (mentor: Mentor): string => {
    return [mentor.firstName, mentor.lastName].join(" ");
  };

  const toListItems = (mentor?: Mentor): ListItem<Mentor> | undefined => {
    if (!mentor) {
      return;
    }
    return {
      id: mentor.id as string,
      title: getFullName(mentor),
      imgSrc: getProfilePic(mentor, ProfilePicSize.List),
      ref: mentor
    }
  }

  const addService = () => {
    setFormMode(FormMode.Adding);
  }

  const editService = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (mentor) {
      const currentServicesIndex = mentor.findIndex(mentor => !!selectedMentor && mentor.id === selectedMentor.id);
      if (currentServicesIndex + 1 < mentor.length && currentServicesIndex !== -1) {
        setSelectedMentor(mentor[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedMentor(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (mentor) {
      const currentServiceIndex = mentor.findIndex(mentor => !!selectedMentor && mentor.id === selectedMentor.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedMentor(mentor[currentServiceIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
      />

      <ListItems
        items={mentor?.map(toListItems) as ListItem<Mentor>[]}
        activeItem={toListItems(selectedMentor)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setMentor(undefined); refresh(); }}
        onClick={(item: ListItem<Mentor>) => { setSelectedMentor(item.ref); }}
      />

      { formMode === undefined ? (
        mentor !== undefined ? (
          selectedMentor ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true}
              onAdd={addService}
              onEdit={editService}
              onDelete={deleteService}
              onPrev={onPrev}
              onNext={onNext}
            >
              <SelectedItemContent
                selectedMentor={selectedMentor}
                getFullName={getFullName}
                getProfilePic={getProfilePic}
                onApprove={ refresh }
              />
            </View>
          ) : (
              <div className="mx-auto py-5 text-center">
                <button onClick={addService} className="btn btn-primary text-bold">{t("list:mentor.addMentor")}</button>
              </div>
            )
        ) : (
            <div className="mx-auto py-5 text-center">
              <p>{t("list:mentor.wait")}</p>
              <Loading loading={true} />
            </div>
          )
      ) : (
          selectedMentor ? (
            <MentorPost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedMentor.id}
              onAfterSave={() => refresh()}
            />
          ) : (
              <MentorPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
        )}
    </List>
  );

}