import { RouteProps } from "react-router-dom";   
import { SpeakerList } from "./list/list.component";
import { SpeakerPost } from "./post/post.component";

export enum SpeakerRoute {
  Post = '/speaker/post',
  List = '/speaker/list'
}

export const StudentRouteMap = [
  {
    path: SpeakerRoute.List,
    component: SpeakerList,
  },
  {
    path: SpeakerRoute.Post,
    component: SpeakerPost,
  }
] as RouteProps[];
