import { Acl } from "../../interfaces/core.interface";

export enum AnnouncementAclCode { 
  ListAllAnnouncement = 'list-all',
  ListOwnAnnouncement = 'list-own', 
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const AnnouncementAcl = [  
  {
    code: AnnouncementAclCode.ListAllAnnouncement,
    title: 'announcement.listAll.title',
    description: 'announcement.listAll.subTitle',
  },
  {
    code: AnnouncementAclCode.ListOwnAnnouncement,
    title: 'announcement.listOwn.title',
    description: 'announcement.listOwn.subTitle',
  },
  {
    code: AnnouncementAclCode.Create,
    title: 'announcement.create.title',
    description: 'announcement.create.subTitle',
  },
  {
    code: AnnouncementAclCode.Update,
    title: 'announcement.update.title',
    description: 'announcement.update.subTitle',
  },
  {
    code: AnnouncementAclCode.Delete,
    title: 'announcement.delete.title',
    description: 'announcement.delete.subTitle',
  }, 
] as Acl<AnnouncementAclCode>;
