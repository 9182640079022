import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use"; 
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter, StatusFilter } from "../../../components/list/filter.component"; 
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Student } from "../../../entities/student.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../services/api.service";
import { getStorageUrl } from "../../../util/file.util";
import { StudentPost } from "../post/post.component";
import MaleImg from '../../../img/male.png';
import { SelectedItemContent } from "./content.component";

export enum ProfilePicSize {
    List = "admin_list",
    Single = "admin_single",
}

const groupFilters: GroupFilter[] = [
    { title: "all", icon: "phone-21" }, 
    { title: "active", icon: "user-check" }, 
    { title: "blocked", icon: "user-x" },
  ];
  
  const statusFilters: StatusFilter[] = [
    { title: "Online", status: ListItemStatus.Green },
    { title: "Metering", status: ListItemStatus.Blue },
    { title: "Offline", status: ListItemStatus.Red },
  ];

export function StudentList() {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [student, setStudent] = useState<Student[] | undefined>(undefined);
  const [selectedStudent, setSelectedStudent] = useState<Student>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [activeStatusFilter, setActiveStatusFilter] = useState<
    StatusFilter | undefined
  >(undefined);

  const { t } = useTranslation(['main', 'list']);

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const status = activeStatusFilter?.title;
    const { items } = await Api.get<IPaginationResponse<Student>, IPaginationOptions>(Endpoint.STUDENT_LIST, { page: 0, limit, group, search });

    console.log('items:',items)
    if (items) {
      setStudent(items);
    }

    if (items.length > 0 && !selectedStudent) {
      setSelectedStudent(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedStudent]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll])

  const deleteService = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.STUDENT, { id: selectedStudent?.id });
    setSelectedStudent(undefined);
    refresh();
  }

  const getProfilePic = (student: Student, type: ProfilePicSize): string => {
    if (student.picFile) {
      return `${getStorageUrl()}/student/${student.id}/pic/${
        student.picFile?.id
      }/${type}.${student.picFile?.extension}`;
    } else {
      return MaleImg;
    }
  };

  const getFullName = (student: Student): string => {
    return [student.firstName, student.lastName].join(" ");
  };

  const toListItems = (student?: Student): ListItem<Student> | undefined => {
    if (!student) {
      return;
    }
    return {
      id: student.id as string,
      title: getFullName(student),
      imgSrc: getProfilePic(student, ProfilePicSize.List),
      ref: student
    }
  }

  const addService = () => {
    setFormMode(FormMode.Adding);
  }

  const editService = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (student) {
      const currentServicesIndex = student.findIndex(student => !!selectedStudent && student.id === selectedStudent.id);
      if (currentServicesIndex + 1 < student.length && currentServicesIndex !== -1) {
        setSelectedStudent(student[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedStudent(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (student) {
      const currentServiceIndex = student.findIndex(student => !!selectedStudent && student.id === selectedStudent.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedStudent(student[currentServiceIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
      />

      <ListItems
        items={student?.map(toListItems) as ListItem<Student>[]}
        activeItem={toListItems(selectedStudent)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setStudent(undefined); refresh(); }}
        onClick={(item: ListItem<Student>) => { setSelectedStudent(item.ref); }}
      />

      { formMode === undefined ? (
        student !== undefined ? (
          selectedStudent ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true}
              onAdd={addService}
              onEdit={editService}
              onDelete={deleteService}
              onPrev={onPrev}
              onNext={onNext}
            >
              <SelectedItemContent
                selectedStudent={selectedStudent}
                getFullName={getFullName}
                getProfilePic={getProfilePic}
                onApprove={ refresh }
              />
            </View>
          ) : (
              <div className="mx-auto py-5 text-center">
                <button onClick={addService} className="btn btn-primary text-bold">{t("list:student.addStudent")}</button>
              </div>
            )
        ) : (
            <div className="mx-auto py-5 text-center">
              <p>{t("list:student.wait")}</p>
              <Loading loading={true} />
            </div>
          )
      ) : (
          selectedStudent ? (
            <StudentPost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedStudent.id}
              onAfterSave={() => refresh()}
            />
          ) : (
              <StudentPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
        )}
    </List>
  );

}