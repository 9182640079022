import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component"; 
import { Speaker } from "../../../entities/speaker.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from "../../../services/api.service";  
import { SpeakerRoute } from "../routes";
import { SpeakerPostPublic } from "./section/public.component";
import { SpeakerPostRegion } from "./section/region.component";

interface Props {
    style?: FormStyle;
    cancel?: Function;
    formMode?: FormMode;
    entityId?: string;
    onAfterSave?: (entity: Speaker) => void;
  }
  
  /**
   * Add or update a driver
   */
  export function SpeakerPost({
    style, cancel, formMode, entityId, onAfterSave,
  }: Props) {
  
    const { t } = useTranslation(['main', 'forms']);
  
    /**
     * Custom Header
     */
    const header = () => (
      style && style === FormStyle.Containerized ? (
        <>
          <SectionsWrapper className="col-lg-6">
            <FormHeader type={ FormHeaderType.Title } title={ t("Speaker") } />
          </SectionsWrapper>
  
          <SectionsWrapper className="col-lg-6">
            <FormHeader type={ FormHeaderType.Controls } />
          </SectionsWrapper>
        </>
      ) : (
        <FormHeader type={ FormHeaderType.Title } title={ t("Speaker") } />
      )
    );
  
    /**
     * Render
     */
    
    return (
      <Form<Speaker>
        endpoint={ Endpoint.SPEAKER }
        returnLink={ SpeakerRoute.List }
        cancel={ cancel }
        formMode={ formMode }
        entityId={ entityId }
        className={ style === FormStyle.Containerized && "ae-content-w" }
        fileEndpoint={ Endpoint.SPEAKER_FILES }
        onAfterSave={ onAfterSave }
        formFiles={[
          { required: true, count: 1, originalName: 'picFile', name: "newPicFile", desc: t("forms:speaker.publicSection.profilePic") },
        ]}
      >
        { style === FormStyle.Containerized && header() }
        <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
          { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.speaker") } /> }
          <FormSections>
            <SpeakerPostPublic />
            <SpeakerPostRegion /> 
          </FormSections>
        </SectionsWrapper> 
      </Form>
    );
  }