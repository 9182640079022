import React from "react";
import { Line } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import stats from './stats.json';

interface StatItem {
  title: string;
  value: string;
  percentage:string;
  trend: string;
}

const labels = ['12-4am', '4-8am', '8-12pm', '12-4pm', '4-8pm', '8-12am'];

const passengerChartData = {
  labels,
  datasets: [
    {
      label: 'stats.newStudent',
      fill: true,
      backgroundColor: '#ff000022',
      borderColor: '#ff0000',
      data: [8, 18, 8, 22, 10, 16]//generateData(6, 10),
    },
  ]
};

const rideChartData = {
  labels,
  datasets: [
    {
      label: 'stats.engage',
      fill: true,
      backgroundColor: '#0000ff22',
      borderColor: '#0000ff',
      data: [10, 24, 8, 36, 12, 20]//generateData(6, 20),
    },
  ]
}

const revenueChartData = {
  labels,
  datasets: [
    {
      label: 'stats.activeStudent',
      fill: true,
      backgroundColor: '#00aa0022',
      borderColor: '#00aa00',
      data: [38, 17, 36, 6, 40, 44]//generateData(6, 2000),
    },
  ]
}

const driverChartData = {
  labels,
  datasets: [
    {
      label: 'stats.activeMentor',
      fill: true,
      backgroundColor: '#00000022',
      borderColor: '#000000',
      data: [10, 14, 10, 10, 12, 14]// generateData(6, 150),
    },
  ]
}

const chartOptions = {
  fill: true,
  borderJoinStyle:"miter",
  scales: {
    xAxes: [{
      gridLines: {
        display: true,
      },
      ticks: {
        display: true,
      }
    }],
    yAxes: [{
      gridLines: {
          display: false,
      },
      ticks: {
        display: false,
      }
    }]
  },
  legend: {
    display: false,
  },
}
 
export function DashboardOverviewStats() {
  const { t } = useTranslation('dashboard');

  return (
    <div className="content-box col-md-9 col-sm-12">
    <div className="element-wrapper">
      <div className="element-actions">
        <form className="form-inline justify-content-sm-end">
          <select className="form-control form-control-sm rounded">
            <option value="Pending">{ t('dropdown.today') }</option>
            <option value="Active">{ t('dropdown.yesterday') }</option>
            <option value="Active">{ t('dropdown.lastWeek') }</option>
            <option value="Cancelled">{ t('dropdown.lastMonth') }</option>
          </select>
        </form>
      </div>
      <h6 className="element-header">{ t('title') }</h6>
      <div className="element-content">
        <div className="tablos">
          <div className="row mb-xl-2 mb-xxl-3">
          { stats.map((stat: StatItem, i: number) => (
            <div key={ i } className="col-sm-3 col-xxxl-6">
              <a href="stat" className="element-box el-tablo">
                <div className="label">{ t(stat.title) }</div>
                <div className="value">{stat.value}</div>
                <div className={`trending trending-${stat.trend === 'positive' ? 'up' : 'down'}-basic`}>
                  <span>{stat.percentage}%</span>
                  <i className={`os-icon os-icon-arrow-${stat.trend === 'positive' ? 'up2' : 'down'}`}></i>
                </div>
              </a>
            </div>
          ))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="element-box pl-xxl-5 pr-xxl-5">
              <div className="el-chart-w">
                <div className="centered-header"><h6>{ t("stats.newStudents") }</h6></div>
                <Line data={passengerChartData} options={chartOptions} />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="element-box pl-xxl-5 pr-xxl-5">
              <div className="el-chart-w">
                <div className="centered-header"><h6>{ t("stats.engage") }</h6></div>
                <Line data={rideChartData} options={chartOptions} />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="element-box pl-xxl-5 pr-xxl-5">
              <div className="el-chart-w">
                <div className="centered-header"><h6>{ t("stats.activeStudents") }</h6></div>
                <Line data={revenueChartData} options={chartOptions} />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="element-box pl-xxl-5 pr-xxl-5">
              <div className="el-chart-w">
                <div className="centered-header"><h6>{ t("stats.activeMentors") }</h6></div>
                <Line data={driverChartData} options={chartOptions} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}