import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Api, Endpoint } from "../../../services/api.service";
import todo from './todo.json';

interface TodoItem {
  header: string;
  subHeader: string;
  completed: boolean;
  link: string;
}

interface DashboardListResponse {
  zoneCount: number;
  vehicleCount: number;
  serviceCount: number;
  driverCount: number;
  fareCount: number;
  settingCount: number;
}

export function DashboardOverviewTodo() {
  const [zoneCount, setZoneCount] = useState<number>();
  const [vehicleCount, setVehicleCount] = useState<number>();
  const [serviceCount, setServiceCount] = useState<number>();
  const [driverCount, setDriverCount] = useState<number>();
  const [fareCount, setFareCount] = useState<number>();
  const [settingCount, setSettingCount] = useState<number>();
  const [percentage, setPercentage] = useState<number>(0);

  /**
   * Selected fare
   */

  /**
   * Determines if fare list is loading
   */

  /**
   * Load fares
   */
  const loadDashboardList = useCallback(async () => {
    const dashboardList: DashboardListResponse = await Api.get(Endpoint.DASHBOARD_COMPLETION);
    console.log(dashboardList);
    setSettingCount(dashboardList.settingCount);
    setZoneCount(dashboardList.zoneCount);
    setDriverCount(dashboardList.driverCount);
    setFareCount(dashboardList.fareCount);
    setServiceCount(dashboardList.serviceCount);
    setVehicleCount(dashboardList.vehicleCount);

    let barPer: number = 0

    if (dashboardList.settingCount > 0) {
      barPer += 20
    }

    if (dashboardList.zoneCount > 0) {
      barPer += 20
    }
    if (dashboardList.serviceCount > 0) {
      barPer += 20
    }
    if (dashboardList.fareCount > 0) {
      barPer += 20
    }
    if (dashboardList.driverCount > 0) {
      barPer += 10
    }
    if (dashboardList.vehicleCount > 0) {
      barPer += 10
    }
    setPercentage(barPer)
  }, [percentage])

  /**
   * Refresh fare list upon zone or service change
   */
  useEffect(() => {
    loadDashboardList();
  }, [loadDashboardList]);

  const { t } = useTranslation('dashboard');
  return (
    <div className="col-md-3 col-sm-12 content-panel color-scheme-dark">
      <div className="element-wrapper compact">
        <h6 className="element-header">{t("todo.title")}</h6>
        <div className="element-box-tp">
          <div className="fancy-progress-with-label">
            <div className="fpl-label">{percentage}%</div>
            <div className="fpl-progress-w">
              <div className="fpl-progress-i" style={{ width: `${percentage}%` }}></div>
            </div>
          </div>
          <div className="todo-list">

            {todo.map((item: TodoItem, i: number) => (
              <Link key={i} className={`todo-item ${item.completed ? 'complete' : ''}`} to={item.link}>
                <div className="ti-info">
                  <div className="ti-header">{t(item.header)}</div>
                  {i === 0 ?
                    <div className="ti-sub-header">{t(item.subHeader)}</div> :
                    i === 1 ?
                      <div className="ti-sub-header">{`You have ${zoneCount} zones setup`}</div> :
                      i === 2 ?
                        <div className="ti-sub-header">{`You have ${serviceCount} services defined`}</div> :
                        i === 3 ?
                          <div className="ti-sub-header">{`You have ${fareCount} Fares Defined`}</div> :
                          i === 4 ?
                            <div className="ti-sub-header">{`You have ${driverCount} drivers registered`}</div> :

                            i === 5 ? <div className="ti-sub-header">{`You have ${vehicleCount} vehicles registered`}</div> : null
                  }
                </div>
                <div className="ti-icon">
                  <i className={`os-icon os-icon-${item.completed ? 'check' : 'arrow-right7'}`}></i>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

}
