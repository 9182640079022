import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use"; 
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Group } from "../../../entities/group.entity";
import { FormMode } from "../../../enums/core.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface"; 
import { Api, Endpoint } from "../../../services/api.service";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
  { title: "active", icon: "user-check" }, 
  { title: "blocked", icon: "user-x" },
];

export function GroupList() {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [Group, setGroup] = useState<Group[] | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined); 
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);

  const { t } = useTranslation(['main', 'list']);

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const status = activeGroupFilter?.title;
    const { items } = await Api.get<IPaginationResponse<Group>, IPaginationOptions>(Endpoint.GROUP_LIST, { page: 0, limit, group, search });

    if (items) {
      setGroup(items);
    }

    if (items.length > 0 && !selectedGroup) {
      setSelectedGroup(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedGroup]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll]) 

  const toListItems = (group?: Group): ListItem<Group> | undefined => {
    if (!group) {
      return;
    }
    return {
      id: group.id as string,
      title: group.title,
      ref: group
    }
  }   

  const onNext = () => {
    if (Group) {
      const currentServicesIndex = Group.findIndex(Group => !!selectedGroup && Group.id === selectedGroup.id);
      if (currentServicesIndex + 1 < Group.length && currentServicesIndex !== -1) {
        setSelectedGroup(Group[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedGroup(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (Group) {
      const currentServiceIndex = Group.findIndex(Group => !!selectedGroup && Group.id === selectedGroup.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedGroup(Group[currentServiceIndex - 1]);
      }
    }
  }

  const getFullName = (group: Group): string => {
    return group.title;
  };

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
      />

      <ListItems
        items={Group?.map(toListItems) as ListItem<Group>[]}
        activeItem={toListItems(selectedGroup)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setGroup(undefined); refresh(); }}
        onClick={(item: ListItem<Group>) => { setSelectedGroup(item.ref); }}
      />

      { formMode === undefined ? (
        Group !== undefined ? (
          selectedGroup ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true} 
              onPrev={onPrev}
              onNext={onNext}
            >
              <SelectedItemContent
                selectedGroup={ selectedGroup }
                getFullName={getFullName}
              />
            </View>
          ) : (
              <div className="mx-auto py-5 text-center"></div>
            )
        ) : (
            <div className="mx-auto py-5 text-center"> </div>
          )
      ) :  null}
    </List>
  );

}