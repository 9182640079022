import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheckBox } from "../../../components/form/input/checkbox.component";
import { FormInput } from "../../../components/form/input/input.component"; 
import { FormUpload } from "../../../components/form/input/upload.component";
import { FormSection } from "../../../components/form/section.component";

export function AddInterest() { 
  
  const { t } = useTranslation('forms');
  const formProps = useFormContext(); 

  return (
    <FormSection
      title=""
      subtitle=""
    > 
        <FormInput
            name="title"
            label={ t("common.title") }
            type="text"
            tabIndex={1}
            autoFocus
            validation={{ required: true }}
            { ...formProps }
        /> 
    </FormSection>
  );
  
}