export enum ModuleName {
  Dashboard = 'dashboard',
  User = 'user',
  Interest = 'interest',
  Region = 'region',
  Majors = 'majors',
  Student = 'student',
  Mentor = 'mentor',
  Speaker = 'speaker',
  Announcement = 'announcement',
  Group = 'group',
}

export enum FormMode {
  Adding,
  Editing,
}
