import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import { Loading } from "../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../components/list/filter.component";
import { ListItem, ListItems } from "../../components/list/items.component";
import { List } from "../../components/list/wrapper.component";
import { View } from "../../components/view/wrapper.component"; 
import { Interest } from "../../entities/interest.entity";
import { FormMode } from "../../enums/core.enum";
import { FormStyle } from "../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../services/api.service'; 
import { InterestPost } from "./post.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function InterestList() {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [interest, setInterest] = useState<Interest[] | undefined>(undefined);
  const [selectedInterest, setSelectedInterest] = useState<Interest>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);

  const { t } = useTranslation(['main', 'list']);

  const refresh = useCallback(async () => {
    const group = activeGroupFilter?.title
    const { items } = await Api.get<IPaginationResponse<Interest>, IPaginationOptions>(Endpoint.INTEREST_LIST, { page: 0, limit, group, search });

    if (items) {
      setInterest(items);
    }

    if (items.length > 0 && !selectedInterest) {
      setSelectedInterest(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedInterest]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll])

  const deleteService = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.INTEREST, { id: selectedInterest?.id });
    setSelectedInterest(undefined);
    refresh();
  }

  const toListItems = (service?: Interest): ListItem<Interest> | undefined => {
    if (!service) {
      return;
    }
    return {
      id: service.id as string,
      title: `${service.title}${service.isDefault ? ' (default)' : ''}`,
      ref: service
    }
  }

  const addService = () => {
    setFormMode(FormMode.Adding);
  }

  const editService = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (interest) {
      const currentServicesIndex = interest.findIndex(interest => !!selectedInterest && interest.id === selectedInterest.id);
      if (currentServicesIndex + 1 < interest.length && currentServicesIndex !== -1) {
        setSelectedInterest(interest[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedInterest(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (interest) {
      const currentServiceIndex = interest.findIndex(interest => !!selectedInterest && interest.id === selectedInterest.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedInterest(interest[currentServiceIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
      />

      <ListItems
        items={interest?.map(toListItems) as ListItem<Interest>[]}
        activeItem={toListItems(selectedInterest)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setInterest(undefined); refresh(); }}
        onClick={(item: ListItem<Interest>) => { setSelectedInterest(item.ref); }}
      />

      { formMode === undefined ? (
        interest !== undefined ? (
          selectedInterest ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true}
              onAdd={addService}
              onEdit={editService}
              onDelete={deleteService}
              onPrev={onPrev}
              onNext={onNext}
            >
              {/* <SelectedItemContent
                selectedService={ selectedService }
              /> */}
            </View>
          ) : (
              <div className="mx-auto py-5 text-center">
                <button onClick={addService} className="btn btn-primary text-bold">{t("list:interest.addInterest")}</button>
              </div>
            )
        ) : (
            <div className="mx-auto py-5 text-center">
              <p>{t("list:interest.wait")}</p>
              <Loading loading={true} />
            </div>
          )
      ) : (
          selectedInterest ? (
            <InterestPost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedInterest.id}
              onAfterSave={() => refresh()}
            />
          ) : (
              <InterestPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
        )}
    </List>
  );

}