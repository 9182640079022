import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";   
import { Announcement } from "../../../entities/announcement";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';  
import { AnnouncementPost } from "../post/post.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function AnnouncementList() {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [announcement, setAnnouncement] = useState<Announcement[] | undefined>(undefined);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<Announcement>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);

  const { t } = useTranslation(['main', 'list']);

  const refresh = useCallback(async () => {
    const group = activeGroupFilter?.title
    const { items } = await Api.get<IPaginationResponse<Announcement>, IPaginationOptions>(Endpoint.ANNOUNCEMENT_LIST, { page: 0, limit, group, search });

    if (items) {
      setAnnouncement(items);
    }

    if (items.length > 0 && !selectedAnnouncement) {
      setSelectedAnnouncement(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedAnnouncement]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll])

  const deleteService = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.ANNOUNCEMENT, { id: selectedAnnouncement?.id });
    setSelectedAnnouncement(undefined);
    refresh();
  }

  const toListItems = (service?: Announcement): ListItem<Announcement> | undefined => {
    if (!service) {
      return;
    }
    return {
      id: service.id as string,
      title: `${service.title}${service.isDefault ? ' (default)' : ''}`,
      ref: service
    }
  }

  const addService = () => {
    setFormMode(FormMode.Adding);
  }

  const editService = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (announcement) {
      const currentServicesIndex = announcement.findIndex(announcement => !!selectedAnnouncement && announcement.id === selectedAnnouncement.id);
      if (currentServicesIndex + 1 < announcement.length && currentServicesIndex !== -1) {
        setSelectedAnnouncement(announcement[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedAnnouncement(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (announcement) {
      const currentServiceIndex = announcement.findIndex(announcement => !!selectedAnnouncement && announcement.id === selectedAnnouncement.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedAnnouncement(announcement[currentServiceIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
      />

      <ListItems
        items={announcement?.map(toListItems) as ListItem<Announcement>[]}
        activeItem={toListItems(selectedAnnouncement)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setAnnouncement(undefined); refresh(); }}
        onClick={(item: ListItem<Announcement>) => { setSelectedAnnouncement(item.ref); }}
      />

      { formMode === undefined ? (
        announcement !== undefined ? (
          selectedAnnouncement ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true}
              onAdd={addService}
              onEdit={editService}
              onDelete={deleteService}
              onPrev={onPrev}
              onNext={onNext}
            >
              {/* <SelectedItemContent
                selectedService={ selectedService }
              /> */}
            </View>
          ) : (
              <div className="mx-auto py-5 text-center">
                <button onClick={addService} className="btn btn-primary text-bold">{t("list:announcement.addAnnouncement")}</button>
              </div>
            )
        ) : (
            <div className="mx-auto py-5 text-center">
              <p>{t("list:announcement.wait")}</p>
              <Loading loading={true} />
            </div>
          )
      ) : (
          selectedAnnouncement ? (
            <AnnouncementPost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedAnnouncement.id}
              onAfterSave={() => refresh()}
            />
          ) : (
              <AnnouncementPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
        )}
    </List>
  );

}