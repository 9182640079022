import { ModuleName } from '../../enums/core.enum';
import { AnnouncementAclCode } from '../../pages/announcement/acl';
import { AnnouncementRoute } from '../../pages/announcement/routes';
import { AuthRoute } from '../../pages/auth/routes';
import { DashboardAclCode } from '../../pages/dashboard/acl';
import { DashboardRoute } from '../../pages/dashboard/routes';  
import { GroupAclCode } from '../../pages/group/acl';
import { GroupRoute } from '../../pages/group/routes';
import { InterestAclCode } from '../../pages/interest/acl';
import { InterestRoute } from '../../pages/interest/routes';
import { MajorsAclCode } from '../../pages/majors/acl';
import { MajorsRoute } from '../../pages/majors/routes';
import { MentorAclCode } from '../../pages/mentors/acl';
import { MentorRoute } from '../../pages/mentors/routes';
import { RegionAclCode } from '../../pages/region/acl';
import { RegionRoute } from '../../pages/region/routes';
import { SpeakerAclCode } from '../../pages/speaker/acl';
import { SpeakerRoute } from '../../pages/speaker/routes';
import { StudentAclCode } from '../../pages/student/acl';
import { StudentRoute } from '../../pages/student/routes';
import { UserAclCode } from '../../pages/user/acl';
import { UserRoute } from '../../pages/user/routes'; 
import { MenuItem } from './header.interface';

export default [
  {
    title: 'mainMenu.main.dashboard',
    link: DashboardRoute.Overview,
    module: ModuleName.Dashboard,
    icon: 'layout',
    submenu: [
      [
        {
          title: 'mainMenu.dashboard.overview',
          link: DashboardRoute.Overview,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Overview,
        },
        // {
        //   title: 'mainMenu.dashboard.revenue',
        //   link: DashboardRoute.Revenue,
        //   module: ModuleName.Dashboard,
        //   code: DashboardAclCode.Revenue,
        // },
        {
          title: 'mainMenu.dashboard.map',
          link: DashboardRoute.Map,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Map,
        },
        {
          title: 'Live Engaging',
          link: DashboardRoute.Engaging,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Engaging,
        },
        // {
        //   title: 'mainMenu.dashboard.trips',
        //   link: DashboardRoute.Trips,
        //   module: ModuleName.Dashboard,
        //   code: DashboardAclCode.Trip,
        // },
      ],
    ],
  },
  {
    title: 'mainMenu.main.announcement',
    link: AnnouncementRoute.List,
    module: ModuleName.Announcement,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.announcement.announcement',
          link: AnnouncementRoute.List,
          module: ModuleName.Announcement,
          code: [AnnouncementAclCode.ListAllAnnouncement, AnnouncementAclCode.ListOwnAnnouncement, AnnouncementAclCode.Create, AnnouncementAclCode.Update, AnnouncementAclCode.Delete],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.group',
    link: GroupRoute.List,
    module: ModuleName.Group,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.group.group',
          link: GroupRoute.List,
          module: ModuleName.Group,
          code: [GroupAclCode.ListAllGroup, GroupAclCode.ListOwnGroup],
        },
      ],
    ],
  },  
  {
    title: 'mainMenu.main.speaker',
    link: SpeakerRoute.List,
    module: ModuleName.Speaker,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.speaker.speaker',
          link: SpeakerRoute.List,
          module: ModuleName.Speaker,
          code: [SpeakerAclCode.ListAllSpeaker, SpeakerAclCode.ListOwnSpeaker, SpeakerAclCode.Create, SpeakerAclCode.Update, SpeakerAclCode.Delete],
        },
      ],
    ],
  },  
  {
    title: 'mainMenu.main.users',
    link: UserRoute.List,
    module: ModuleName.User,
    icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.users.users',
          link: UserRoute.List,
          module: ModuleName.User,
          code: [UserAclCode.ListAll, UserAclCode.ListOwn, UserAclCode.Create],
        },
        {
          title: 'mainMenu.users.roles',
          link: UserRoute.ListRole,
          module: ModuleName.User,
          code: [UserAclCode.ListAllRoles, UserAclCode.ListOwnRoles, UserAclCode.CreateRole],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.student',
    link: StudentRoute.List,
    module: ModuleName.Student,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.student.student',
          link: StudentRoute.List,
          module: ModuleName.Student,
          code: [StudentAclCode.ListAllStudent, StudentAclCode.ListOwnStudent, StudentAclCode.Create, StudentAclCode.Update, StudentAclCode.Delete],
        },
      ],
    ],
  },  
  {
    title: 'mainMenu.main.mentors',
    link: MentorRoute.List,
    module: ModuleName.Mentor,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.mentor.mentor',
          link: MentorRoute.List,
          module: ModuleName.Mentor,
          code: [MentorAclCode.ListAllMentor, MentorAclCode.ListOwnMentor, MentorAclCode.Create, MentorAclCode.Update, MentorAclCode.Delete],
        },
      ],
    ],
  },  
  {
    title: 'mainMenu.main.interest',
    link: InterestRoute.List,
    module: ModuleName.Interest,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.interest.interest',
          link: InterestRoute.List,
          module: ModuleName.Interest,
          code: [InterestAclCode.ListAllInterest, InterestAclCode.ListOwnInterest, InterestAclCode.Create, InterestAclCode.Update, InterestAclCode.Delete],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.region',
    link: RegionRoute.List,
    module: ModuleName.Region,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.region.region',
          link: RegionRoute.List,
          module: ModuleName.Region,
          code: [RegionAclCode.ListAllRegion, RegionAclCode.ListOwnRegion, RegionAclCode.Create, RegionAclCode.Update, RegionAclCode.Delete],
        },
      ],
    ],
  },  
  {
    title: 'mainMenu.main.majors',
    link: MajorsRoute.List,
    module: ModuleName.Majors,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.majors.majors',
          link: MajorsRoute.List,
          module: ModuleName.Majors,
          code: [MajorsAclCode.ListAllMajors, MajorsAclCode.ListOwnMajors, MajorsAclCode.Create, MajorsAclCode.Update, MajorsAclCode.Delete],
        },
      ],
    ],
  },  
  {
    title: 'mainMenu.settings.logout',
    link: AuthRoute.Logout,
    module: ModuleName.User,
    isPublic: true,
    icon: 'log-out',
  },
] as MenuItem[];
