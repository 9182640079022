import { Acl } from "../../interfaces/core.interface";

export enum MajorsAclCode { 
  ListAllMajors = 'list-all',
  ListOwnMajors = 'list-own', 
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const MajorsAcl = [  
  {
    code: MajorsAclCode.ListAllMajors,
    title: 'majors.listAll.title',
    description: 'majors.listAll.subTitle',
  },
  {
    code: MajorsAclCode.ListOwnMajors,
    title: 'majors.listOwn.title',
    description: 'majors.listOwn.subTitle',
  },
  {
    code: MajorsAclCode.Create,
    title: 'majors.create.title',
    description: 'majors.create.subTitle',
  },
  {
    code: MajorsAclCode.Update,
    title: 'majors.update.title',
    description: 'majors.update.subTitle',
  },
  {
    code: MajorsAclCode.Delete,
    title: 'majors.delete.title',
    description: 'majors.delete.subTitle',
  }, 
] as Acl<MajorsAclCode>;
