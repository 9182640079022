import { Acl } from "../../interfaces/core.interface";

export enum RegionAclCode { 
  ListAllRegion = 'list-all',
  ListOwnRegion = 'list-own', 
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const RegionAcl = [  
  {
    code: RegionAclCode.ListAllRegion,
    title: 'region.listAll.title',
    description: 'region.listAll.subTitle',
  },
  {
    code: RegionAclCode.ListOwnRegion,
    title: 'region.listOwn.title',
    description: 'region.listOwn.subTitle',
  },
  {
    code: RegionAclCode.Create,
    title: 'region.create.title',
    description: 'region.create.subTitle',
  },
  {
    code: RegionAclCode.Update,
    title: 'region.update.title',
    description: 'region.update.subTitle',
  },
  {
    code: RegionAclCode.Delete,
    title: 'region.delete.title',
    description: 'region.delete.subTitle',
  }, 
] as Acl<RegionAclCode>;
