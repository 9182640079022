import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component'; 
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';  
import { Mentor, MentorStatus } from '../../../entities/mentor.entity';
import { Api, Endpoint } from '../../../services/api.service';   
import { ProfilePicSize } from './list.component';

interface Props {
    selectedMentor: Mentor;
    getFullName: (mentor: Mentor) => string;
    getProfilePic: (mentor: Mentor, size: ProfilePicSize) => string;
    onApprove?: () => void,
}

export function SelectedItemContent({
    selectedMentor, getFullName, getProfilePic, onApprove
}: Props) {

    const [mentor, setMentor] = useState<Mentor>();
    // const [, setInspection] = useState<Inspection[]>();
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    // const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);

    useEffect(() => {
        init(); 
    }, [selectedMentor])

    const init = useCallback(async () => {
        setMentor(selectedMentor);
        const mentor = await Api.get<Mentor, { id: string }>(Endpoint.MENTOR, { id: selectedMentor.id as string });
        console.log(mentor);
        setMentor(mentor);
    }, [selectedMentor]);

    const [confirmingApproval, setConfirmingApproval] = useState(false);
    const [approved, setApproved] = useState(false);

    useEffect(() => {
        setApproved(false);
        setConfirmingApproval(false);
    }, [selectedMentor]);  

    return (
        <ContentWrapper className="row">
            <ViewContent
                className="col-md-6"
                title={getFullName(selectedMentor)}
                subtitle={selectedMentor.code}
                imgSrc={getProfilePic(selectedMentor, ProfilePicSize.Single)}
            >
                <ViewContentItems> 

                    <ViewContentItem title="Email">
                        <a href={`mailto:${mentor?.email}`}>{mentor?.email}</a>
                    </ViewContentItem>

                    <ViewContentItem title="Affiliation">
                        <a href={`${mentor?.affiliations}`}>{mentor?.affiliations}</a>
                    </ViewContentItem>  

                    <ViewContentItem title="Social Media Links">
                        {mentor?.socialMediaLinks}
                    </ViewContentItem> 

                    <ViewContentItem title="Region">
                        {mentor?.region?.map(n => n.title).join(', ')}
                    </ViewContentItem>  
                    </ViewContentItems>
                    </ViewContent>
        </ContentWrapper>
    );
}