import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next"; 
import { Majors } from "../../../../entities/majors.entity";
import {FormSection} from "../../../../components/form/section.component"
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";
import { FormSelect } from "../../../../components/form/input/select.component";
import { Interest } from "../../../../entities/interest.entity";

export function StudentPostInterestAndMajors() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [interest, setInterest] = useState<Interest[]>([]);
  const [majors, setMajors] = useState<Majors[]>([]);

  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const interestList = await Api.get<IPaginationResponse<Interest>, IPaginationOptions>(Endpoint.INTEREST_LIST);
    setInterest(interestList.items);
    const majorsList = await Api.get<IPaginationResponse<Majors>, IPaginationOptions>(Endpoint.MAJORS_LIST);
    setMajors(majorsList.items);
  }

  return (
      <>
      <FormSection
      title={ t("Interest") }
      subtitle={ t("Which region of interest this student have?") }
      >
          <div className="row">
        <FormSelect<Interest>
          label={ t("Region of interest") }
          name="interest"
          className="col-sm-12"
          showLabel={ false }
          multiple={ true }
          validation={{}}
          items={ interest }
          labelField="title"
          onChange={ () => {} }
          tabIndex={16}
          { ...formProps }
        />
      </div>
       
      </FormSection>
      <FormSection
      title={ t("Majors") }
      subtitle={ t("Which major degree this student have?") }
      >
        <div className="row">
            <FormSelect<Majors>
                label={ t("Majors") }
                name="major"
                className="col-sm-12"
                showLabel={ false }
                multiple={ true }
                validation={{}}
                items={ majors }
                labelField="title"
                onChange={ () => {} }
                tabIndex={16}
                { ...formProps }
            />
        </div>
      </FormSection>
      </>
  ); 

}