// @ts-nocheck
import { LoadScript } from "@react-google-maps/api";
import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { Helmet } from "react-helmet"; 
import { Header } from "./components/header/header.component";
import { PrivateRoute } from "./pages/auth/private-route.component";
import { AuthRoute, AuthRouteMap } from "./pages/auth/routes";
import { DashboardAclCode } from "./pages/dashboard/acl";
import { DashboardModule } from "./pages/dashboard/module";
import { DashboardRoute } from "./pages/dashboard/routes";  
import { UserAclCode } from "./pages/user/acl";
import { UserModule } from "./pages/user/module"; 
import "./scss/main.scss";
import { AclService } from "./services/acl.service";
import { AuthService } from "./services/auth.service";
import "./services/i18n";
import * as serviceWorker from "./serviceWorker";   
import { Api } from "./services/api.service"; 
import { InterestAclCode } from "./pages/interest/acl";
import { InterestModule } from "./pages/interest/module";
import { RegionAclCode } from "./pages/region/acl";
import { RegionModule } from "./pages/region/module";
import { MajorsAclCode } from "./pages/majors/acl";
import { MajorsModule } from "./pages/majors/module";
import { StudentModule } from "./pages/student/module";
import { StudentAclCode } from "./pages/student/acl";
import { MentorAclCode } from "./pages/mentors/acl";
import { MentorModule } from "./pages/mentors/module";
import { SpeakerModule } from "./pages/speaker/module";
import { SpeakerAclCode } from "./pages/speaker/acl";
import { AnnouncementAclCode } from "./pages/announcement/acl";
import { AnnouncementModule } from "./pages/announcement/module";
import { GroupAclCode } from "./pages/group/acl";
import { GroupModule } from "./pages/group/module";

const favicon = require(`./img/${process.env.REACT_APP_FAVICON}`);

AclService.setModuleActions<DashboardAclCode>(
  DashboardModule.name,
  DashboardModule.acl
);  
AclService.setModuleActions<UserAclCode>(UserModule.name, UserModule.acl); 
AclService.setModuleActions<InterestAclCode>(InterestModule.name, InterestModule.acl);
AclService.setModuleActions<RegionAclCode>(RegionModule.name, RegionModule.acl);
AclService.setModuleActions<MajorsAclCode>(MajorsModule.name, MajorsModule.acl);
AclService.setModuleActions<StudentAclCode>(StudentModule.name, StudentModule.acl);
AclService.setModuleActions<MentorAclCode>(MentorModule.name, MentorModule.acl);
AclService.setModuleActions<SpeakerAclCode>(SpeakerModule.name, SpeakerModule.acl);
AclService.setModuleActions<AnnouncementAclCode>(AnnouncementModule.name, AnnouncementModule.acl);
AclService.setModuleActions<GroupAclCode>(GroupModule.name, GroupModule.acl);





const routeMap: RouteProps[] = [
  DashboardModule.routes,  
  UserModule.routes, 
  InterestModule.routes,
  RegionModule.routes,
  MajorsModule.routes,
  StudentModule.routes,
  MentorModule.routes,
  SpeakerModule.routes,
  AnnouncementModule.routes,
  GroupModule.routes,
].reduce((a, v) => a.concat(v), []);

export default function App() {
  // const [requests, setRequests] = useState<Emergency[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Api.on('initializing', () => setLoading(true));
    Api.on('initialized', () => setLoading(false));
  }, []) 

//   useEffect(() => {
//     // if(AuthService.isLoggedIn()) {
//     //   ChatService.init();
//     // }
//     // Api.on('emergency_alert', async (payload: any) => {
//     //   console.log("RECEIVED EMERGENCY EVENT!");
//     //   await EmergencyFunctions.handleRequest(payload).then(async () => {
//     //     console.log("Pending Requests: " + EmergencyFunctions.CountPendingRequests());
//     //   await EmergencyFunctions.handleRequest(payload).then(()=>{
//     //     setRequests([...EmergencyFunctions.GetAllRequests()]);
//     //   });
//     // });
//     // Api.on('modify_alert', async(payload: any)=>{
//     //   EmergencyFunctions.updatePendings(payload);
//     //   setRequests([...EmergencyFunctions.GetAllRequests()]);
//     // });
//     // ChatService.init();
//   // });
// }, []);

  return (
    <div className="auth-container all-wrapper with-side-panel solid-bg-all">
     <Helmet>
        <title>{`${process.env.REACT_APP_NAME} Admin`}</title>
        <link rel='icon' href={favicon} />
      </Helmet>
      <div className="layout-w">
        {/* Only show header when authenticated */}
        {loading && <div>Loading...</div>}
        {AuthService.isLoggedIn() && !loading && <Header />}
        <div className="content-w">
          <Switch>
            {/* Private routes require authentication */}
            <Route exact path="/">
              <Redirect
                to={
                  (!loading && AuthService.isLoggedIn())
                    ? DashboardRoute.Overview
                    : AuthRoute.Login
                }
              />
            </Route>
            {routeMap.map((routeProps) => (
              <PrivateRoute key={routeProps.path as string} {...routeProps} loading={loading} />
            ))}
            {/* Authentication routes - public */}
            {AuthRouteMap.map((routeProps) => (
              <Route key={routeProps.path as string} {...routeProps} />
            ))}
            <Route path="*">
              <Redirect
                to={
                  (!loading && AuthService.isLoggedIn())
                    ? DashboardRoute.Overview
                    : AuthRoute.Login
                }
              />
            </Route>
          </Switch>
          {/* <RequestStacker Data={requests} MaxRequests={3}/> */}
        </div>
      </div>
    </div>
  );
}

const AppRouter = withRouter(App);

ReactDOM.render(
  <Suspense fallback="loading">
    <Router>
      <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/react-draft-wysiwyg@1.12.3/dist/react-draft-wysiwyg.css' />
      <LoadScript
        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
        libraries={["places", "drawing"]}
      >
        <AppRouter />
      </LoadScript>
    </Router>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
