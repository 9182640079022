import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component"; 
import { Student } from "../../../entities/student.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from "../../../services/api.service";
import { StudentRoute } from "../routes";
import { StudentPostInterestAndMajors } from "./section/interest.component";
import { StudentPostPublic } from "./section/public.component";

interface Props {
    style?: FormStyle;
    cancel?: Function;
    formMode?: FormMode;
    entityId?: string;
    onAfterSave?: (entity: Student) => void;
  }
  
  /**
   * Add or update a driver
   */
  export function StudentPost({
    style, cancel, formMode, entityId, onAfterSave,
  }: Props) {
  
    const { t } = useTranslation(['main', 'forms']);
  
    /**
     * Custom Header
     */
    const header = () => (
      style && style === FormStyle.Containerized ? (
        <>
          <SectionsWrapper className="col-lg-6">
            <FormHeader type={ FormHeaderType.Title } title={ t("Student") } />
          </SectionsWrapper>
  
          <SectionsWrapper className="col-lg-6">
            <FormHeader type={ FormHeaderType.Controls } />
          </SectionsWrapper>
        </>
      ) : (
        <FormHeader type={ FormHeaderType.Title } title={ t("Student") } />
      )
    );
  
    /**
     * Render
     */
    
    return (
      <Form<Student>
        endpoint={ Endpoint.STUDENT }
        returnLink={ StudentRoute.List }
        cancel={ cancel }
        formMode={ formMode }
        entityId={ entityId }
        className={ style === FormStyle.Containerized && "ae-content-w" }
        fileEndpoint={ Endpoint.STUDENT_FILES }
        onAfterSave={ onAfterSave }
        formFiles={[
          { required: true, count: 1, originalName: 'picFile', name: "newPicFile", desc: t("forms:student.publicSection.profilePic") },
        ]}
      >
        { style === FormStyle.Containerized && header() }
        <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
          { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.student") } /> }
          <FormSections>
            <StudentPostPublic />
            <StudentPostInterestAndMajors /> 
          </FormSections>
        </SectionsWrapper> 
      </Form>
    );
  }