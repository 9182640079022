import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";  
import {FormSection} from "../../../../components/form/section.component"
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";
import { FormSelect } from "../../../../components/form/input/select.component"; 
import { Region } from "../../../../entities/region.entity";

export function SpeakerPostRegion() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [region, setRegion] = useState<Region[]>([]); 

  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const regionList = await Api.get<IPaginationResponse<Region>, IPaginationOptions>(Endpoint.REGION_LIST);
    setRegion(regionList.items); 
  }

  return ( 
      <FormSection
      title={ t("Region") }
      subtitle={ t("Region where this mentor live") }
      >
          <div className="row">
        <FormSelect<Region>
          label={ t("Region") }
          name="region"
          className="col-sm-12"
          showLabel={ false }
          multiple={ true }
          validation={{}}
          items={ region }
          labelField="title"
          onChange={ () => {} }
          tabIndex={16}
          { ...formProps }
        />
      </div>  
      </FormSection> 
  ); 

}