import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormPropsContext } from "../../../../components/form/form.component";
import { FormInput } from "../../../../components/form/input/input.component"; 
import { FormUpload, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";  
import { Speaker } from "../../../../entities/speaker.entity";
import { FormMode } from "../../../../enums/core.enum"; 

export function SpeakerPostPublic() {

  const { t } = useTranslation('forms');
  const { mode } = useContext(FormPropsContext);
  const formProps = useFormContext();
  const { getValues } = formProps;

  const getProfilePicUrls = (): UploadPreviewUrl[] | undefined => {
    const form: Speaker = getValues() as Speaker; 
    console.log('form:', form)
    if (form.id && mode === FormMode.Editing) {
      return [getProfilePic(form)];
    }
    return undefined;
  }

  const getProfilePic = (speaker: Speaker): UploadPreviewUrl => {
    const pic = speaker.picFile; 
    return {
      thumb: getUrl(`/speaker/${speaker.id}/pic/${pic?.id}/admin_single.${pic?.extension}`),
      full: getUrl(`/speaker/${speaker.id}/pic/${pic?.id}/admin_large.${pic?.extension}`),
    }
  } 

  return (
    <FormSection
      title={ t("speaker.publicSection.title") }
      subtitle={ t("speaker.publicSection.subTitle") }
    >
      <div className="row">
        <FormUpload
          urls={ getProfilePicUrls() }
          label={ t("Profile Pic") }
          name="newPicFile"
          originalName="picFile"
          className="col-md-4 col-sm-12"
          validation={{ required: true }}
          tabIndex={4}
          { ...formProps }
        />
        </div>
        <div className="row">
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("common.firstName") }
            name="firstName"
            type="text"
            autoFocus
            tabIndex={1}
            validation={{ required: true }}
            { ...formProps }
          /> 
        </div>
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("common.lastName") }
            name="lastName"
            type="text"
            validation={{ required: true }}
            tabIndex={2}
            { ...formProps }
          />
        </div>
      </div>
      <div className="row">
      <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("common.email") }
            name="email"
            type="email"
            autoFocus
            tabIndex={1}
            validation={{ required: true }}
            { ...formProps }
          /> 
        </div>
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("common.password") }
            name="password"
            type="password"
            validation={{ required: true }}
            tabIndex={2}
            { ...formProps }
          />
        </div>
      </div>
      <div className="row">
      <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("Social Media Links") }
            name="socialMediaLinks"
            type="text"
            autoFocus
            tabIndex={1}
            validation={{ required: true }}
            { ...formProps }
          /> 
        </div>
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("affiliations") }
            name="affiliations"
            type="text"
            validation={{ required: true }}
            tabIndex={2}
            { ...formProps }
          />
        </div>
      </div>
    </FormSection>
  );

}