import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentFiles } from '../../../components/view/files.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component'; 
import { IFile } from '../../../entities/file.entity';
import { getStorageUrl } from '../../../util/file.util';
import { capitalize } from '../../../util/string.util';   
import { Api, Endpoint } from '../../../services/api.service';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Modal } from 'react-responsive-modal'; 
import { Student, StudentStatus } from '../../../entities/student.entity';
import { ProfilePicSize } from './list.component';

interface Props {
    selectedStudent: Student;
    getFullName: (student: Student) => string;
    getProfilePic: (student: Student, size: ProfilePicSize) => string;
    onApprove?: () => void,
}

export function SelectedItemContent({
    selectedStudent, getFullName, getProfilePic, onApprove
}: Props) {

    const [student, setStudent] = useState<Student>();
    // const [, setInspection] = useState<Inspection[]>();
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    // const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);

    useEffect(() => {
        init(); 
    }, [selectedStudent])

    const init = useCallback(async () => {
        setStudent(selectedStudent);
        const student = await Api.get<Student, { id: string }>(Endpoint.STUDENT, { id: selectedStudent.id as string });
        console.log(student);
        setStudent(student);
    }, [selectedStudent]);

    const [confirmingApproval, setConfirmingApproval] = useState(false);
    const [approved, setApproved] = useState(false);

    useEffect(() => {
        setApproved(false);
        setConfirmingApproval(false);
    }, [selectedStudent]); 

    
    return (
        <ContentWrapper className="row">
            <ViewContent
                className="col-md-6"
                title={getFullName(selectedStudent)}
                subtitle={selectedStudent.code}
                imgSrc={getProfilePic(selectedStudent, ProfilePicSize.Single)}
            >
                <ViewContentItems> 

                    <ViewContentItem title="Email">
                        <a href={`mailto:${student?.email}`}>{student?.email}</a>
                    </ViewContentItem>

                    <ViewContentItem title="Looking for">
                        <a href={`${student?.looking}`}>{student?.looking}</a>
                    </ViewContentItem>  

                    <ViewContentItem title="Home Address">
                        {student?.address}
                    </ViewContentItem> 

                    <ViewContentItem title="Interest">
                        {student?.interest?.map(n => n.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title="Major">
                        {student?.major?.map(n => n.title).join(', ')}
                    </ViewContentItem> 
                    </ViewContentItems>
                    </ViewContent>
        </ContentWrapper>
    );
}