import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component'; 
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';  
import { Speaker, SpeakerStatus } from '../../../entities/speaker.entity';
import { Api, Endpoint } from '../../../services/api.service';   
import { ProfilePicSize } from './list.component';

interface Props {
    selectedSpeaker: Speaker;
    getFullName: (speaker: Speaker) => string;
    getProfilePic: (speaker: Speaker, size: ProfilePicSize) => string;
    onApprove?: () => void,
}

export function SelectedItemContent({
    selectedSpeaker, getFullName, getProfilePic, onApprove
}: Props) {

    const [speaker, setSpeaker] = useState<Speaker>();
    // const [, setInspection] = useState<Inspection[]>();
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    // const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);

    useEffect(() => {
        init(); 
    }, [selectedSpeaker])

    const init = useCallback(async () => {
        setSpeaker(selectedSpeaker);
        const speaker = await Api.get<Speaker, { id: string }>(Endpoint.SPEAKER, { id: selectedSpeaker.id as string });
        console.log(speaker);
        setSpeaker(speaker);
    }, [selectedSpeaker]);

    const [confirmingApproval, setConfirmingApproval] = useState(false);
    const [approved, setApproved] = useState(false);

    useEffect(() => {
        setApproved(false);
        setConfirmingApproval(false);
    }, [selectedSpeaker]);  

    return (
        <ContentWrapper className="row">
            <ViewContent
                className="col-md-6"
                title={getFullName(selectedSpeaker)}
                subtitle={selectedSpeaker.code}
                imgSrc={getProfilePic(selectedSpeaker, ProfilePicSize.Single)}
            >
                <ViewContentItems> 

                    <ViewContentItem title="Email">
                        <a href={`mailto:${speaker?.email}`}>{speaker?.email}</a>
                    </ViewContentItem>

                    <ViewContentItem title="Affiliation">
                        <a href={`${speaker?.affiliations}`}>{speaker?.affiliations}</a>
                    </ViewContentItem>  

                    <ViewContentItem title="Social Media Links">
                        {speaker?.socialMediaLinks}
                    </ViewContentItem> 

                    <ViewContentItem title="Region">
                        {speaker?.region?.map(n => n.title).join(', ')}
                    </ViewContentItem>  
                    </ViewContentItems>
                    </ViewContent>
        </ContentWrapper>
    );
}