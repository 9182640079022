import { Acl } from "../../interfaces/core.interface";

export enum StudentAclCode { 
  ListAllStudent = 'list-all',
  ListOwnStudent = 'list-own', 
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const StudentAcl = [  
  {
    code: StudentAclCode.ListAllStudent,
    title: 'student.listAll.title',
    description: 'student.listAll.subTitle',
  },
  {
    code: StudentAclCode.ListOwnStudent,
    title: 'student.listOwn.title',
    description: 'student.listOwn.subTitle',
  },
  {
    code: StudentAclCode.Create,
    title: 'student.create.title',
    description: 'student.create.subTitle',
  },
  {
    code: StudentAclCode.Update,
    title: 'student.update.title',
    description: 'student.update.subTitle',
  },
  {
    code: StudentAclCode.Delete,
    title: 'student.delete.title',
    description: 'student.delete.subTitle',
  }, 
] as Acl<StudentAclCode>;
