import { Acl } from "../../interfaces/core.interface";

export enum InterestAclCode { 
  ListAllInterest = 'list-all',
  ListOwnInterest = 'list-own', 
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const InterestAcl = [  
  {
    code: InterestAclCode.ListAllInterest,
    title: 'interest.listAll.title',
    description: 'interest.listAll.subTitle',
  },
  {
    code: InterestAclCode.ListOwnInterest,
    title: 'interest.listOwn.title',
    description: 'interest.listOwn.subTitle',
  },
  {
    code: InterestAclCode.Create,
    title: 'interest.create.title',
    description: 'interest.create.subTitle',
  },
  {
    code: InterestAclCode.Update,
    title: 'interest.update.title',
    description: 'interest.update.subTitle',
  },
  {
    code: InterestAclCode.Delete,
    title: 'interest.delete.title',
    description: 'interest.delete.subTitle',
  }, 
] as Acl<InterestAclCode>;
