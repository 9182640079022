import { RouteProps } from "react-router-dom";  
import { MajorsList } from "./list.component";
import { MajorsPost } from "./post.component";

export enum MajorsRoute {
  Post = '/majors/post',
  List = '/majors/list'
}

export const MajorsRouteMap = [
  {
    path: MajorsRoute.List,
    component: MajorsList,
  },
  {
    path: MajorsRoute.Post,
    component: MajorsPost,
  }
] as RouteProps[];
